// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/render/project/src/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-console-js": () => import("/opt/render/project/src/src/templates/console.js" /* webpackChunkName: "component---src-templates-console-js" */),
  "component---src-templates-insight-js": () => import("/opt/render/project/src/src/templates/insight.js" /* webpackChunkName: "component---src-templates-insight-js" */),
  "component---src-templates-expertise-js": () => import("/opt/render/project/src/src/templates/expertise.js" /* webpackChunkName: "component---src-templates-expertise-js" */),
  "component---src-pages-404-js": () => import("/opt/render/project/src/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/render/project/src/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-approach-js": () => import("/opt/render/project/src/src/pages/approach.js" /* webpackChunkName: "component---src-pages-approach-js" */),
  "component---src-pages-campus-js": () => import("/opt/render/project/src/src/pages/campus.js" /* webpackChunkName: "component---src-pages-campus-js" */),
  "component---src-pages-careers-js": () => import("/opt/render/project/src/src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-console-js": () => import("/opt/render/project/src/src/pages/console.js" /* webpackChunkName: "component---src-pages-console-js" */),
  "component---src-pages-contact-js": () => import("/opt/render/project/src/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-distributed-teams-js": () => import("/opt/render/project/src/src/pages/distributed-teams.js" /* webpackChunkName: "component---src-pages-distributed-teams-js" */),
  "component---src-pages-index-js": () => import("/opt/render/project/src/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insights-js": () => import("/opt/render/project/src/src/pages/insights.js" /* webpackChunkName: "component---src-pages-insights-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/render/project/src/.cache/data.json")

